.contentWrap {
  padding: 20.5px 0 20.5px 0;
}
.contentWrap {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.label {
  font-weight: 400 !important;
}
.input {
  font-size: 14px !important;
  font-weight: 400 !important;
}
