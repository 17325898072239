.icon {
  width: 20px !important;
  height: 20px !important;
  fill: #00b779 !important;
}

.text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
}

.container {
  background-color: #e5fff7;
  border-radius: 5px;
  color: #00b779;
  border: 1.5px solid #00b779;
}
