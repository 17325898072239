.payoutStatusText {
  color: white;
  font-size: 14px;
  padding: 2px 4px;
  border-radius: 2px;
}

.cachedIcon {
  width: 16px !important;
  height: 16px !important;
  color: #493ab1;
}

.retry {
  border-bottom: 1px solid #493ab1;
}
