.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 54px;
}
.payoutImg {
  margin-bottom: 15px;
}
.heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.54px;
  margin-bottom: 8px;
}
.subHeading {
  color: rgba(39, 37, 34, 0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.7px;
  margin-bottom: 2px;
}
.buttons {
  display: flex;
  margin-top: 16px;
}
.secondaryButton {
  color: #493ab1 !important;
  font-weight: 500 !important;
}
.icons {
  margin-right: 4px;
}
.primaryBtn {
  background-color: #00b77a !important;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 500;
}
.sendRemainder {
  background-color: #ffaa00 !important;
}
@media screen and (max-width: 780px) {
  .container {
    padding: 24px 20px;
    text-align: center;
  }
}
