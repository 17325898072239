.cta {
  font-size: 14px;
  font-weight: 400;
  color: #493ab1;
}

.icon {
  fill: #493ab1 !important;
  font-size: 14px !important;
}
