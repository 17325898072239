.svg_container {
  padding: 15px;
  border-radius: 50px;
}

.payment_svg {
  font-size: 78px !important;
}

.header {
  font-weight: 500;
  font-size: 18px;
}

.fail_header {
  font-weight: 700;
  font-size: 20px;
}

.description {
  color: #272522;
  font-size: 14px;
  font-weight: 400;
  width: 90%;
}

.line {
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  width: 100%;
}

.disclaimer {
  background-color: rgba(238, 236, 249, 1);
  border: 1px solid rgba(238, 236, 249, 1);
  border-radius: 5px;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
  width: 90%;
}

.close_modal {
  position: absolute;
  right: 10px;
  top: 10px;
}

.value {
  font-size: 14px;
  font-weight: 500;
}

.title {
  font-size: 14px;
  font-weight: 400;
}

.payment_modal {
  width: 380px;
}

.fail_icon {
  fill: #bf2600 !important;
}

@media screen and (max-width: 780px) {
  .payment_modal {
    width: 100%;
  }
}
