.wrapper {
  display: flex;
  gap: 30px;
  padding: 24px;
  flex-direction: column;
}
.container {
  display: flex;
  gap: 20px;
}
.desktopModalPaper {
  width: 651px !important;
  max-width: 651px !important;
}
.discountCode {
  font-size: 18px;
  font-weight: 500;
}
.disabled {
  color: black !important;
  background-color: rgba(246, 246, 249, 1) !important;
  cursor: not-allowed;
}
.text {
  font-size: 16px;
  font-weight: 400;
  color: #625f72;
  margin-bottom: 10px;
}
.rightWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}
.discountInput {
  width: 100%;
  height: 47px !important;
  background-color: rgba(246, 246, 249, 1) !important;
}
.buttonWrapper {
  display: flex;
  gap: 20px;
}
.buttonWrapperClassName {
  width: 50% !important;
}
.secondaryButton {
  width: 100% !important;
}
.copyLink {
  min-width: 0px !important;
  height: 47px !important;
}
.footerBtn {
  gap: 10px;
}
.copyLink:hover .affiliateCopyIcon {
  filter: brightness(0) invert(1);
}
@media screen and (max-width: 780px) {
  .container {
    flex-direction: column;
  }
  .discountImg {
    width: 146px;
  }
  .input {
    width: 100% !important;
  }
  .discountImg {
    margin: 0 auto !important;
  }
  .discountCode,
  .text {
    text-align: center !important;
  }
  .discountCode {
    font-size: 16px;
  }
  .text {
    font-size: 14px;
  }
  .rightWrapper {
    margin-top: 0px;
    gap: 5px;
  }
  .footerClassName {
    gap: 10px;
  }
  .secondaryButton {
    width: 100%;
    font-size: 14px !important;
  }
}
