.desktopModalPaper {
  width: 677px;
  max-width: 677px;
}
.tabWrapper {
  margin-top: 16px;
}
@media (max-width: 780px) {
  .modalPaper {
    height: 92vh !important;
    overflow-y: hidden !important;
  }
}
