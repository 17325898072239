.reject {
  color: #bf2600 !important;
}
.select {
  line-height: 18px !important;
}
.approve {
  color: #009966 !important;
}
.dropdownRoot {
  width: 125px !important;
  font-weight: 500 !important;
}
