.container {
  padding: 28px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.headerButtonWrapper {
  display: flex;
  gap: 10px;
}
.name_field {
  color: #493ab1;
  font-size: 14px;
  line-height: 16px;
  cursor: default !important;
}
.iconStyle {
  font-size: 16px !important;
}
.acceptBtn {
  color: #009966 !important;
  background-color: white !important;
}
.rejectBtn {
  color: #bf2600 !important;
  background-color: white !important;
}
.acceptWrapper {
  border: 1px solid #009966;
  border-radius: 5px;
}
.rejectWrapper {
  border: 1px solid #bf2600;
  border-radius: 4px;
}
.checkIcon {
  fill: #009966 !important;
  width: 13px !important;
  height: 13px !important;
  margin-right: 2px;
}
.bulkApproveIcon {
  fill: #009966 !important;
  width: 16px !important;
  height: 16px !important;
}
.crossIcon {
  fill: #bf2600 !important;
  width: 13px !important;
  height: 13px !important;
  margin-right: 2px;
}
.actionsWrapper {
  display: flex;
  gap: 12px;
}
.name_field {
  text-decoration: none !important;
}
.bulkRejectIcon {
  fill: #bf2600 !important;
  width: 16px !important;
  height: 16px !important;
}

.checkIcon {
  width: 75px !important;
  height: 75px !important;
  fill: #009966 !important;
}

.autoApprovedTitle {
  font-weight: 700;
  font-style: 16px;
  color: #272522;
}

.autoApprovedDescription {
  color: #272522;
  font-size: 14px;
}

@media screen and (max-width: 780px) {
  .container {
    background-color: rgba(252, 251, 254, 1);
    padding: 0px;
  }
  .headerButtonWrapper {
    justify-content: space-between;
  }
  .name_field {
    font-size: "14px";
    line-height: "20px";
  }
}
